// Scroll into view to trigger animation
// Add directive: v-in-view
// Optional: v-in-view:repeat = animate when scrolling out of view
// or v-in-view:norepeat (default) = animate only once, when scrolling into view

// Prepare options for IntersectionObserver
const options = {
  root: null,
  threshold: 0.4,
  trackVisibility: false
}

// Prepare callback for IntersectionObserver
function callback(entries, observer) {
  // For each entry, check if isIntersecting
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      // If so, add class 'enter'.
      entry.target.classList.add('inview-animation--enter');
      entry.target.classList.remove('inview-animation--before-enter');

      // Now we can stop observing entry
      observer.unobserve(entry.target);
    }
  });
}

// Prepare options for repeating IntersectionObserver
const optionsRepeat = {
  root: null,
  threshold: 0.4,
  trackVisibility: false
}

// Prepare callback for repeating IntersectionObserver
function callbackRepeat(entries) {
  // For each entry, check if isIntersecting
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      // If so, add class enter
      entry.target.classList.add('inview-animation--enter');
      entry.target.classList.remove('inview-animation--before-enter');
      
    } else if (!entry.isIntersecting) {
      // Else if not isIntersecting, revert to before-enter
      entry.target.classList.add('inview-animation--before-enter');
      entry.target.classList.remove('inview-animation--enter');
    }
  });
}

const animationScrollObserver = new IntersectionObserver(callback, options);
const animationScrollObserverRepeat = new IntersectionObserver(callbackRepeat, optionsRepeat);

export default {
  beforeMount(el, binding) {
    // Start by giving all affected elements a before-enter class.
    el.classList.add('inview-animation--before-enter');

    // If no argument passed or if norepeat passed
    if (binding.arg === undefined || binding.arg === 'norepeat') {
      // Trigger an IntersectionObserver to animate if in view.
      animationScrollObserver.observe(el);
    } else if (binding.arg === 'repeat') {
      // Else if repeat passed, trigger an IntersectionObserver to animate if in view, and if going out of view
      animationScrollObserverRepeat.observe(el)
    }
  }
}