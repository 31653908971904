import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import InView from './directives/inview.js'

// Import Global CSS
import '@/styles/_style.scss'

createApp(App)
.use(store)
.use(router)
.directive('in-view', InView)
.mount('#app')
