<template>
  <div class="featured-case__animation-wrapper" :ref="uuid" :id="uuid" v-in-view:norepeat>
    <div class="featured-case" :class="{'inverted': instance.inverted}">
      <div class="featured-case__text-container" >
        <h2 class="caption">{{ instance.caseName }}</h2>
        <h3 class="h2">{{ instance.title }}</h3>
        <p class="body-m">{{ instance.introduction }}</p>
      </div>
      <div class="featured-case__illustration-container">
        <img :src="require(`@/assets/images/${instance.imageName}`)" alt="case image" />
      </div>
    </div>
  </div>
</template>

<script>
import uidGenerator from '@/mixins/uid-generator'

export default {
  name: 'FeaturedCase',
  props: {
    instance: {
      type: Object,
      required: true
    }
  },
  mixins: [
    uidGenerator
  ],
  data() {
    return {
      position: 0
    }
  },
  mounted() {
    this.handleScrollEvent;
    window.addEventListener('scroll', this.handleScrollEvent);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScrollEvent);
  },
  methods: {
    handleScrollEvent() {
      // calculate translateY so that if (middle of screen == middle of container) {translateY = 0};
      const wrapper = this.$refs[this.uuid];
      const container = wrapper.querySelector(':scope .featured-case__illustration-container');
      const wrapperClientRect = wrapper.getBoundingClientRect();
      const wrapperTop = wrapperClientRect.top;
      const wrapperHeight = wrapperClientRect.height;
      const windowHeight = window.innerHeight;
      const deltaMiddle = (windowHeight / 2) - (wrapperTop + wrapperHeight / 2);
      
      let translateBy = 0;
      const translateModifier = 0.025;
      // Apply max (or min in negative) value
      if (deltaMiddle > 0) {
        translateBy = Math.min(deltaMiddle, wrapperHeight);
      } else if (deltaMiddle < 0) {
        translateBy = Math.max(deltaMiddle, -wrapperHeight)
      }
      // minus delta equals parallax up on scroll down and vice versa
      container.style.transform = `translateY(${translateModifier * -translateBy}px)`;

    }
  }
}
</script>

<style lang="scss" scoped>

.featured-case__animation-wrapper {
  background-color: $primary-white;

  &:nth-child(2n) {
    background-color: $secondary-white;
  }

  &.inview-animation--before-enter {
    
    .featured-case {
      opacity: 0;
      transform: translateY(50px);
      transition: opacity .5s ease-out, transform .5s ease-out;
    }
  }

  &.inview-animation--enter {

    .featured-case {
      opacity: 1;
      transform: translateY(0px);
      transition: opacity .5s ease-out, transform .5s ease-out;
    }
  }

  .featured-case {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    // height: 640px;
    min-height: 640px;

    .featured-case__text-container {
      max-width: 500px;
      margin: 0 auto;

      .caption {
        color: $primary-complementary;
        margin-bottom: 4px;
      }

      .h2 {
        margin-bottom: 16px;
      }

      .body-m {
        margin-bottom: 16px;
      }
    }

    .featured-case__illustration-container {
      text-align: center;
      margin: 0 auto;
      max-width: 500px;
      transition: transform .25s ease-out;

      img {
        width: auto;
        max-width: 100%;
        max-height: 320px;
        transition: transform .5s ease-out;
      }
    }
  }
}


@media (min-width: 1000px) {
  .featured-case__animation-wrapper {
    
    .featured-case {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    
      .featured-case__text-container {
        width: 50%;
        margin: 0;
        margin-right: 32px;
      }

      .featured-case__illustration-container {
        width: 50%;
        margin: 0;
        margin-left: 32px;
      }

      &.inverted {
        
        .featured-case__text-container {
          margin-right: 0;
          margin-left: 32px;
        }

        .featured-case__illustration-container {
          margin-left: 0;
          margin-right: 32px;
          order: -1;
        }
      }
    }
  }
}

</style>
